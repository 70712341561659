<template>
  <el-cascader  size="small"  :options="deptTree" :show-all-levels="false" :props="cascProps" filterable placeholder="请选择部门" v-model="department"></el-cascader>
</template>

<script>
import { getDeptTree } from './api'
export default {
    name: 'SelectDept',

    props: {
        value: {
            type: [String,Number],
            default: ''
        }
    },

    computed: {
        department: {
            get () {
                return this.value
            },

            set (val) {
                this.$emit('input', val)
            }
        }
    },

    data () {
        return {
            deptTree: [],
            cascProps: {
                label: 'deptName',
                value: 'id',
                checkStrictly: true,
                expandTrigger: 'hover',
                emitPath: false,
                multiple:false,
                leaf: 'leaf'
            }
        }
    },

    created () {
        this.getDeptTree()
    },

    methods: {
        async getDeptTree () {
            let res = await getDeptTree()
            this.deptTree = this.setLeaf(res)
        },

        setLeaf (list) {
            return list.map(item => {
                if (item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
                else {
                    item.leaf = true
                    delete item.children
                }
                return item
            })
        }
    }
}
</script>

<style>

</style>