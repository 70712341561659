<template>
  <el-select
    style="width:250px;"
    size="small"
    v-model="jobId"
    :loading="isLoading"
    filterable
    remote
    :remote-method="getJobList"
    placeholder="请选择"
  >
    <el-option v-for="item in list" :label="item.jobName" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getJobListAPI } from './api.js'
export default {
  name: 'SelectJob',

  props: {
    value: {
      type: [String,Number],
      default: ''
    }
  },

  computed: {
    jobId: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getJobList('')
  },

  methods: {
    async getJobList(query) {
      let params = {
        page: 1,
        pageSize: 20,
        name: query
      }
      let res = await getJobListAPI(params)
      this.list = res.list
    }
  }
}
</script>

<style></style>
